const viewportKeys = ['iphone5', 'mobile', 'tablet', 'desktop', 'largeScreen'] as const
type ViewportKeys = typeof viewportKeys
type ViewportKey = ViewportKeys[number]

type ViewportMap = Record<ViewportKey, number>

const viewport: ViewportMap = {
  iphone5: 320,
  mobile: 375,
  tablet: 768,
  desktop: 992,
  largeScreen: 1440
}

export { viewport }
