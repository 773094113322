import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import styled, { css } from 'styled-components'

import downDefaultIcon from '../../assets/images/down_default.svg'
import downPrimaryIcon from '../../assets/images/down_primary.svg'
import { space } from '../../constants/length'
import { LightGrey, Primary, Secondary, White } from '../../constants/newColor'
import { NavInfo, SwitchableProps } from '.'

const activeStyleMenuItem = css`
  background-color: ${LightGrey};
`

const MobileNavMenuItemWrapper = styled.div<SwitchableProps>`
  background-color: ${White};
  cursor: pointer;
  padding: ${space.s}px ${space.l}px;

  ${props => props.isActive && activeStyleMenuItem}
`

const activeStyleBordered = css`
  border-bottom: 1px solid ${Primary};
  color: ${Primary};
`

const DownIcon = styled.img`
  width: ${space.m}px;
  height: ${space.m}px;
`

const baseStyleBordered = css`
  border-bottom: 1px solid transparent;
`

const Bordered = styled.div<SwitchableProps>`
  box-sizing: border-box;
  display: flex;
  color: ${Secondary};
  justify-content: space-between;
  line-height: 2;

  ${props => (props.isActive ? activeStyleBordered : baseStyleBordered)}

  &:hover {
    ${activeStyleBordered}
  }
`

export type MobileNavMenuItemLinkProps = SwitchableProps &
  Pick<NavInfo, 'label' | 'path'> & {
    showArrow?: boolean
  }

type MobileNavMenuItemProps = Omit<MobileNavMenuItemLinkProps, 'path'>

export const MobileNavMenuItem = (props: MobileNavMenuItemProps) => {
  const { isActive, label, showArrow = false } = props

  return (
    <MobileNavMenuItemWrapper isActive={isActive}>
      <Bordered isActive={isActive}>
        <div>{label}</div>
        {showArrow && <DownIcon src={isActive ? downDefaultIcon : downPrimaryIcon} alt='' />}
      </Bordered>
    </MobileNavMenuItemWrapper>
  )
}

export const MobileNavMenuItemLink = (props: MobileNavMenuItemLinkProps) => {
  const { isActive = false, label, path, showArrow = false } = props

  return (
    <Link to={path}>
      <MobileNavMenuItem isActive={isActive} label={label} showArrow={showArrow} />
    </Link>
  )
}
