import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import closePrimaryIcon from '../../assets/images/close_primary.svg'
import desktopLogoImage from '../../assets/images/logo_desktop.svg'
import mobileLogoImage from '../../assets/images/logo_mobile.svg'
import menuDefaultIcon from '../../assets/images/menu_default.svg'
import { desktopHeaderHeight, mobileHeaderHeight, space } from '../../constants/length'
import { Primary, Secondary } from '../../constants/newColor'
import { viewport } from '../../constants/viewport'
import { hexToRGBA } from '../../utils/color'
import Button from '../Button'
import DesktopMenuList from './DesktopMenuList'
import MobileNavMenuList from './MobileNavMenuList'
import getNavInfoList from './navInfoList'

export interface SwitchableProps {
  isActive: boolean
}

export interface NavMenu {
  label: string
  path: string
}

export type NavInfo = NavMenu & {
  subMenuList: NavMenu[]
}

/** Header 特有的斷點，由於 1024 會跑版，先暫時設置成 1124，等之後新的設計 */
const headerBreakpoint = 1124

const HeaderWrapper = styled.div<{ isDesktopMenuVisible: boolean; pageYOffset: number }>`
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: center;

  background-color: ${Primary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 10;
  transition: top 0.3s;

  @media (min-width: ${headerBreakpoint}px) {
    flex-direction: row;
    top: ${props => (props.pageYOffset < desktopHeaderHeight || props.isDesktopMenuVisible ? '0' : `-${desktopHeaderHeight}px`)};
  }
  @media (max-width: ${headerBreakpoint - 1}px) {
    flex-direction: column;
    top: ${props => (props.pageYOffset < mobileHeaderHeight || props.isDesktopMenuVisible ? '0' : `-${mobileHeaderHeight}px`)};
  }
`

const MaxWidthWrapper = styled.div`
  border-bottom: solid 1px ${hexToRGBA(Secondary, 0.5)};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${space.xxl}px;
  height: ${desktopHeaderHeight}px;

  @media (max-width: ${headerBreakpoint - 1}px) {
    padding: 0 ${space.m}px;
  }

  @media (max-width: ${headerBreakpoint - 1}px) {
    height: ${mobileHeaderHeight}px;
  }
`

const MenuIcon = styled.div`
  @media (min-width: ${headerBreakpoint}px) {
    display: none;
  }
  @media (max-width: ${headerBreakpoint - 1}px) {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
  }

  img {
    width: ${space.l}px;
    height: ${space.l}px;
  }
`

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0;

  @media (min-width: ${headerBreakpoint}px) {
    justify-content: flex-start;
  }
  @media (max-width: ${headerBreakpoint - 1}px) {
    justify-content: center;
  }

  img {
    @media (min-width: ${viewport.tablet}px) {
      height: 38px;
    }
    @media (max-width: ${viewport.tablet - 1}px) {
      height: 30px;
    }
  }
`

const LogoAndIconWrapper = styled.div`
  display: flex;

  ${MenuIcon}, ${Logo} {
    flex: 1;
  }

  @media (max-width: ${headerBreakpoint - 1}px) {
    justify-content: center;
    width: 100%;

    &::after {
      content: '';
      flex: 1;
    }
  }
`

const DesktopMenu = styled.div`
  @media (min-width: ${headerBreakpoint}px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: ${headerBreakpoint - 1}px) {
    display: none;
  }
`

const getCurrentPageYOffset = () => (typeof window !== 'undefined' ? window.pageYOffset : 0)

const Header = () => {
  const { formatMessage } = useIntl()

  const navInfoList = getNavInfoList(formatMessage)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const [desktopState, setDesktopState] = useState({
    prevYOffset: 0,
    visible: false
  })

  const handleExpandMenu = () => {
    document.body.style.overflow = 'hidden'
    setIsMenuExpanded(true)
  }

  const handleCloseMenu = () => {
    document.body.style.overflow = ''
    setIsMenuExpanded(false)
  }

  const handleScroll = () => {
    const currentYOffset = getCurrentPageYOffset()
    setDesktopState(state => ({
      prevYOffset: currentYOffset,
      visible: currentYOffset < state.prevYOffset
    }))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleCloseMenu()
    }
  }, [])

  return (
    <HeaderWrapper isDesktopMenuVisible={desktopState.visible} pageYOffset={desktopState.prevYOffset}>
      <MaxWidthWrapper>
        <LogoAndIconWrapper>
          <MenuIcon>
            {isMenuExpanded ? (
              <img onClick={() => handleCloseMenu()} src={closePrimaryIcon} alt='關閉' />
            ) : (
              <img onClick={() => handleExpandMenu()} src={menuDefaultIcon} alt='分類' />
            )}
          </MenuIcon>
          <Logo to='/'>
            <img src={mobileLogoImage} alt='日不落牙醫集團' srcSet={`${mobileLogoImage} 320w, ${desktopLogoImage} 992w`} />
            日不落牙醫集團
          </Logo>
        </LogoAndIconWrapper>
        <DesktopMenu>
          <DesktopMenuList navInfoList={navInfoList} />
          <a href='#footer_form'>
            <Button
              label={formatMessage({ id: 'button.consult' })}
              type='primary'
              onClick={() => {
                trackCustomEvent({
                  category: 'contact',
                  action: 'click',
                  label: 'instant',
                  value: 1
                })
              }}
              btnSquareColor={Primary}
            />
          </a>
        </DesktopMenu>
      </MaxWidthWrapper>
      <div>{isMenuExpanded && <MobileNavMenuList navInfoList={navInfoList} handleCloseMenu={handleCloseMenu} />}</div>
    </HeaderWrapper>
  )
}

export default Header
