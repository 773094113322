import React from 'react'

import { getClinicInfo } from './Clinic/getClinicInfo'

interface Props {
  name: string
  phone: string
  line: string
  location: string
  content: string
  updateForm: (e) => void
}

/* 問卷Submit後自動發送通知到各診所信箱 */
const EmailNotifyForm = (props: Props) => {
  const { name, phone, line, location, content, updateForm } = props

  return (
    <form id='emailNotify'>
      <input type='text' name='name' value={name} onChange={updateForm} />
      <input type='text' name='phone' value={phone} onChange={updateForm} />
      <input type='text' name='line' value={line} onChange={updateForm} />
      <input type='text' name='location' value={getClinicInfo({ location: location || 'TAIPEI', value: 'name' })} onChange={updateForm} />
      <input type='text' name='toLocationMail' value={getClinicInfo({ location: location || 'TAIPEI', value: 'email' })} onChange={updateForm} />
      <input type='text' name='content' value={content} onChange={updateForm} />
    </form>
  )
}

export default EmailNotifyForm
