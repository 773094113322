import React from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import { LightGrey, Primary } from '../../constants/newColor'
import { hexToRGBA } from '../../utils/color'

const CopyrightWrapper = styled.div`
  background-color: ${Primary};
  color: ${hexToRGBA(LightGrey, 0.85)};
  display: flex;
  font-family: NotoSansCJKtc;
  justify-content: center;
  letter-spacing: normal;
  line-height: 2.18;
  padding: ${space.s}px 0;
  width: 100%;
`

interface CopyrightProps {
  copyright: string
}

const Copyright = ({ copyright }: CopyrightProps) => {
  return <CopyrightWrapper>{copyright}</CopyrightWrapper>
}

export default Copyright
