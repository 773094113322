import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import { LightGrey, Primary, Secondary, White } from '../../constants/newColor'
import { hexToRGBA } from '../../utils/color'
import { NavInfo } from '.'

const subMenuWidth = 100

const LinkWrapper = styled(Link)`
  height: 100%;
`

const DesktopSubMenuMenuItemWrapper = styled.div`
  background-color: ${hexToRGBA(LightGrey, 1)};
  color: ${Secondary};
  cursor: pointer;
  line-height: 2;
  padding: ${space.s}px 0px ${space.s}px ${space.m}px;
  width: ${subMenuWidth}px;

  &:hover {
    background-color: ${Primary};
    color: ${White};
  }
`

type DesktopSubMenuMenuItemProps = Pick<NavInfo, 'label' | 'path'> & {
  onClick: () => void
}

const DesktopSubMenuMenuItem = (props: DesktopSubMenuMenuItemProps) => {
  const { label, path, onClick } = props

  return (
    <LinkWrapper to={path}>
      <DesktopSubMenuMenuItemWrapper onClick={onClick}>{label}</DesktopSubMenuMenuItemWrapper>
    </LinkWrapper>
  )
}

export default DesktopSubMenuMenuItem
