import { ThemeProvider as ChakraThemeProvider } from '@chakra-ui/core'
import { PageRendererProps } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import theme from '../../constants/theme'
import Footer from '../Footer'
import Header from '../Header'
import Seo from '../Seo'

export const LocationContext = React.createContext<PageRendererProps['location'] | undefined>(undefined)

export const ThemeProvider: React.FC = props => <ChakraThemeProvider theme={theme}>{props.children}</ChakraThemeProvider>

const Wrapper = styled.div`
  overflow-x: hidden;
`

type LayoutProps = PageRendererProps & {
  withContactMenu?: boolean
}

const Layout: React.FC<LayoutProps> = props => {
  const { children, location } = props
  return (
    <>
      <Seo />
      <LocationContext.Provider value={location}>
        <ThemeProvider>
          <Header />
          <Wrapper>{children}</Wrapper>
          <Footer />
        </ThemeProvider>
      </LocationContext.Provider>
    </>
  )
}

export default Layout
