import { Grid, IGrid } from '@chakra-ui/core'
import { mergeDeepRight } from 'ramda'
import React from 'react'

import { space } from '../../constants/length'

export type GridLayoutProps = Pick<IGrid, 'templateRows' | 'templateAreas' | 'rowGap' | 'columnGap'> & {
  templateColumns: NonNullable<IGrid['templateColumns']>
}

type GridItemProps = Pick<IGrid, 'area' | 'row' | 'column'>

/**
 * 需要使用二維排版時，可使用 GridItem 傳入指定渲染的區域
 */
export const GridItem: React.FC<GridItemProps> = props => {
  const { children, ...restProps } = props
  return <Grid {...restProps}>{children}</Grid>
}

export const GridLayout: React.FC<GridLayoutProps> = props => {
  const { children, ...customGridProps } = props
  const defaultGridStyle: IGrid = {
    columnGap: [`${space.s}px`, `${space.s}px`, `${space.m}px`]
  }
  const gridProps = mergeDeepRight(defaultGridStyle, customGridProps)

  return <Grid {...gridProps}>{children}</Grid>
}
