import { map } from 'ramda'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import useOutsideClick from '../../utils/useOutsideClick'
import { DesktopMenuItem } from './DesktopMenuItem'
import DesktopSubMenuItem from './DesktopSubMenuItem'
import { NavInfo, SwitchableProps } from '.'

const DesktopSubMenuItemWrapper = styled.div`
  position: absolute;
`

type DesktopMenuItemWithSubMenuProps = SwitchableProps & Pick<NavInfo, 'label' | 'subMenuList'>

const DesktopMenuItemWithSubMenu = (props: DesktopMenuItemWithSubMenuProps) => {
  const { isActive, label, subMenuList } = props
  const [isShowingSubMenu, setIsShowingSubMenu] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleSubMenuClose = () => {
    setIsShowingSubMenu(false)
  }

  useOutsideClick(ref, handleSubMenuClose)

  return (
    <div onMouseLeave={() => setIsShowingSubMenu(false)} onMouseOver={() => setIsShowingSubMenu(true)} ref={ref}>
      <DesktopMenuItem isActive={isActive} label={label} showArrow />
      {isShowingSubMenu && (
        <DesktopSubMenuItemWrapper>
          {map(
            subMenu => (
              <DesktopSubMenuItem key={`desktop-header-sub-menu-${subMenu.label}}`} label={subMenu.label} path={subMenu.path} onClick={handleSubMenuClose} />
            ),
            subMenuList
          )}
        </DesktopSubMenuItemWrapper>
      )}
    </div>
  )
}

export default DesktopMenuItemWithSubMenu
