const textSizeKeys = ['h1Web', 'h2Web', 'h2Mobile', 'h3Web', 'h3Mobile', 'h4Web', 'h4Mobile', 'body'] as const
type TextSizeKeyTuple = typeof textSizeKeys
type TextSize = Record<TextSizeKeyTuple[number], number>

const textSize: TextSize = {
  h1Web: 40,
  h2Web: 24,
  h2Mobile: 20,
  h3Web: 20,
  h3Mobile: 16,
  h4Web: 16,
  h4Mobile: 12,
  body: 14
}

export { textSize }
