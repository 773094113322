import { PageRendererProps } from 'gatsby'
import { length, map } from 'ramda'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { mobileHeaderHeight } from '../../campaign/utils/constant'
import { SecondaryText } from '../../constants/color'
import { LocationContext } from '../Layout'
import { isActiveMenu } from './DesktopMenuList'
import { MobileNavMenuItemLink } from './MobileNavMenuItem'
import MobileNavMenuItemWithSubMenu from './MobileNavMenuItemWithSubMenu'
import { NavInfo } from '.'

const MobileNavMenuListWrapper = styled.div`
  background-color: ${SecondaryText};
  height: calc(100vh - ${mobileHeaderHeight}px);
  position: absolute;
  width: 100%;
  z-index: 10;
`

interface MobileNavMenuListProps {
  navInfoList: NavInfo[]
  handleCloseMenu: () => void
}

const MobileNavMenuList = (props: MobileNavMenuListProps) => {
  const { navInfoList, handleCloseMenu } = props
  const [expandKey, setExpandKey] = useState<string>()
  const location = useContext<PageRendererProps['location'] | undefined>(LocationContext)

  const handleToggleSubmenu = (key: string | undefined) => {
    setExpandKey(key)
  }
  return (
    <MobileNavMenuListWrapper onClick={handleCloseMenu}>
      {map(navInfo => {
        const isActive = location ? isActiveMenu(navInfo, location.pathname) : false

        const hasSubMenu = length(navInfo.subMenuList) !== 0
        return hasSubMenu ? (
          <MobileNavMenuItemWithSubMenu
            isActive={isActive}
            isExpanded={expandKey === navInfo.label}
            handleToggleSubmenu={handleToggleSubmenu}
            key={`nav-menu-${navInfo.label}`}
            label={navInfo.label}
            subMenuList={navInfo.subMenuList}
            handleCloseMenu={handleCloseMenu}
          />
        ) : (
          <MobileNavMenuItemLink isActive={isActive} key={`nav-menu-${navInfo.label}`} label={navInfo.label} path={navInfo.path} />
        )
      }, navInfoList)}
    </MobileNavMenuListWrapper>
  )
}

export default MobileNavMenuList
