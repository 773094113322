const Primary = '#202020'
const Secondary = 'rgba(32, 32, 32, 0.8)'
const DarkGrey = '#6c6c6c'
const LightGrey = '#f5f5f5'
const Grey = '#e9e8e8'
const LightBG = 'rgba(246, 246, 246, 0.50)'
const White = '#ffffff'

// 診所色票
const Taipei = '#ad5b9c'
const TaipeiSecondary = '#705A72'
const Guting = '#6B3906'
const GutingSecondary = '#D9D9D9'
const Hsinchu = '#f8b62d'
const HsinchuSecondary = '#D9D9D9'
const Taichung = '#5a6e56'
const TaichungSecondary = '#AE965A'
const Tainan = '#9ab05f'
const TainanSecondary = '#727171'
const TainanYellow = '#ffe246'

export {
  Primary,
  LightGrey,
  Secondary,
  DarkGrey,
  Grey,
  LightBG,
  Taipei,
  TaipeiSecondary,
  Guting,
  GutingSecondary,
  Hsinchu,
  HsinchuSecondary,
  Taichung,
  TaichungSecondary,
  Tainan,
  TainanSecondary,
  TainanYellow,
  White
}
