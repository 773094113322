import React from 'react'
import styled, { css } from 'styled-components'

import lineIcon from '../assets/images/line.jpg'
import { space } from '../constants/length'
import { LightGrey, Primary, White } from '../constants/newColor'
import { Btn } from '../constants/newText'

export type ButtonType = 'primary' | 'secondary'

const hoverLineLength = 10

interface ColorProps {
  color?: string
}

const Label = styled.div<ColorProps>`
  ${Btn}
  color: ${props => (props.color ? props.color : Primary)};
`

interface BackgroundColorProps {
  btnSquareColor: string
}

const PrimaryWrapper = styled.div<BackgroundColorProps>`
  position: relative;
  background-color: ${Primary};
  height: 42px;
  width: 99px;

  ::before {
    content: '';
    display: block;
    width: ${hoverLineLength}px;
    height: ${hoverLineLength}px;
    position: absolute;
    top: -${hoverLineLength}px;
    left: 0px;
    background-color: ${props => props.btnSquareColor};
    z-index: 2;
    transition: 0.2s;
  }
  ::after {
    content: '';
    display: block;
    width: ${hoverLineLength}px;
    height: ${hoverLineLength}px;
    position: absolute;
    bottom: 0px;
    right: -${hoverLineLength}px;
    background-color: ${props => props.btnSquareColor};
    z-index: 2;
    transition: 0.2s;
  }

  &:hover {
    ::after,
    ::before {
      transform: translate(8px, 0px);
    }
  }

  &:active {
    ::after,
    ::before {
      transform: translate(4px, 0px);
    }
  }
`

const LeftHoverLine = styled.div`
  width: ${hoverLineLength + 1}px;
  height: ${hoverLineLength + 1}px;
  position: absolute;
  top: -${hoverLineLength + 1}px;
  left: -5px;
  border-right: 1px solid ${Primary};
  transform: rotate(45deg);
`

const RightHoverLine = styled.div`
  width: ${hoverLineLength + 1}px;
  height: ${hoverLineLength + 1}px;
  position: absolute;
  bottom: 2px;
  right: -5px;
  border-right: 1px solid ${Primary};
  transform: rotate(45deg);
`

const primaryStyle = css`
  position: absolute;
  background-color: ${White};
  border: 1px solid ${Primary};
  color: ${Primary};
  margin: 0 auto;
  padding: ${space.s}px ${space.m}px;
  transition: 0.2s;
  z-index: 3;
`

const Wrapper = styled.div`
  padding: ${space.m}px 0;
`

const secondaryStyle = css`
  background-color: ${White};
  color: ${LightGrey};
  border-radius: ${space.xl}px;
  padding: ${space.s}px ${space.l}px;
  position: relative;
`

const LineIcon = css<ColorProps>`
  margin-right: 0.5rem;
  background-image: url(${lineIcon});
  background-color: ${props => (props.color ? props.color : Primary)};
  background-blend-mode: lighten;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

const Icon = styled.div`
  width: ${space.l}px;
  height: ${space.l}px;
  ${LineIcon}
`

const hoverPrimaryStyle = css`
  transform: translate(8px, -8px);
`
const hoverSecondaryStyle = css`
  opacity: 0.8;
`
const disabledStyle = css`
  cursor: not-allowed;
  opacity: 0.45;
`
const loadingStyle = css`
  cursor: progress;
  opacity: 0.45;
`

interface StyledButtonProps {
  buttonType: ButtonType
  disabled: boolean
  loading: boolean
}

const StyledButton = styled.div<StyledButtonProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${space.xs}px ${space.xl}px;

  width: 100%;
  height: 100%;

  ${props => (props.buttonType === 'secondary' ? secondaryStyle : primaryStyle)}
  ${props => (props.disabled ? disabledStyle : '')}
  ${props => (props.loading ? loadingStyle : '')}

  &:hover {
    ${props => !props.disabled && (props.buttonType === 'secondary' ? hoverSecondaryStyle : hoverPrimaryStyle)}
  }

  &:active {
    transform: translate(4px, -4px);
  }
  img {
    margin-right: ${space.s}px;
  }
`

export type ButtonProps = ColorProps & {
  hasIcon?: boolean
  disabled?: boolean
  loading?: boolean
  label: string
  type: ButtonType
  onClick?: () => void
  btnSquareColor?: string
}

const Button = (props: ButtonProps) => {
  const { hasIcon = false, disabled = false, loading = false, label, type, onClick, color = Primary, btnSquareColor = White } = props

  return (
    <>
      {type === 'primary' && (
        <PrimaryWrapper btnSquareColor={btnSquareColor}>
          <LeftHoverLine />
          <StyledButton buttonType={type} disabled={disabled} loading={loading} onClick={onClick}>
            {hasIcon && <Icon color={color} />}
            <Label>{label}</Label>
          </StyledButton>
          <RightHoverLine />
        </PrimaryWrapper>
      )}
      {type === 'secondary' && (
        <Wrapper>
          <StyledButton buttonType={type} disabled={disabled} loading={loading} onClick={onClick}>
            {hasIcon && <Icon color={color} />}
            <Label color={color}>{label}</Label>
          </StyledButton>
        </Wrapper>
      )}
    </>
  )
}

export default Button
