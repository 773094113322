import { gql, useMutation } from '@apollo/client'
import { Form as AntdForm, Input as AntdInput, message } from 'antd'
import emailjs from 'emailjs-com'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { useIntl } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { space } from '../constants/length'
import { Grey, LightGrey, Primary } from '../constants/newColor'
import { Caption as _Caption } from '../constants/newText'
import { viewport } from '../constants/viewport'
import { CommentStatus, CreateSurveyMutation, CreateSurveyMutationVariables, Location } from '../types/types'
import Button from './Button'
import ClinicSelect from './ClinicSelect'
import EmailNotifyForm from './EmailNotifyForm'
import { GridLayout } from './Grid'

const serviceId = process.env.EMAIL_SERVICE_ID || ''
const templateId = process.env.EMAIL_TEMPLATE_ID || ''
const publicId = process.env.EMAIL_PUBLIC_KEY || ''

const Wrapper = styled.div`
  border: 1px ${Primary} solid;
  padding: ${space.l}px;
  @media (max-width: ${viewport.tablet - 1}px) {
    border: none;
  }
`
const EmailNotifyFormWrapper = styled.div`
  display: none;
`
const createSurvey = gql`
  mutation CreateSurvey($payload: CreateCommentInput!) {
    createComment(payload: $payload) {
      id
      name
      phone
      line
      location
      content
      status
    }
  }
`
const Form = styled(AntdForm)``
const FormItem = styled(AntdForm.Item)`
  &.ant-form-item {
    margin-bottom: ${space.m}px;
  }
`

const inputStyle = css`
  width: 100%;
  background-color: ${Grey};
  border: solid 1px ${Grey};
  &:hover {
    outline: none;
    border: solid 1px #a9a9a9;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    border: solid 1px ${Primary};
    box-shadow: none;
  }
`

const Input = styled(AntdInput)`
  ${inputStyle}
`

const TextArea = styled(AntdInput.TextArea)`
  ${inputStyle}
  &.ant-input:focus {
    box-shadow: none;
  }
`

const Caption = styled.span`
  ${_Caption}
  margin-left: ${space.m}px;
  @media (max-width: ${viewport.desktop - 1}px) {
    margin-top: ${space.m}px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${viewport.desktop - 1}px) {
    flex-direction: column;
  }
`
interface SurveyFormFields {
  name: string
  phone: string
  line: string
  location: Location
  content: string
  status: CommentStatus
}

const getPayloadFromFieldsValue = (fieldsValue: SurveyFormFields) => ({
  name: fieldsValue.name,
  phone: fieldsValue.phone,
  line: fieldsValue.line,
  location: fieldsValue.location,
  content: fieldsValue.content,
  status: CommentStatus.Pending
})

interface SurveyFormProps {
  formContentLabel?: string
}

const ContactSurvey = (props: SurveyFormProps) => {
  const { formContentLabel } = props
  const { formatMessage } = useIntl()
  const [form] = Form.useForm<SurveyFormFields>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [notifyEmailForm, setNotifyEmailForm] = useState({
    name: '',
    line: '',
    phone: '',
    location: '',
    toLocationMail: '',
    content: ''
  })

  const updateForm = e => {
    setNotifyEmailForm({
      ...notifyEmailForm,
      [e.target.name]: e.target.value
    })
  }

  const [create] = useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(createSurvey)

  const sendEmail = () => {
    emailjs.sendForm(serviceId, templateId, '#emailNotify', publicId).then(
      function(response) {
        console.log('SUCCESS!', response.status, response.text)
      },
      function(error) {
        console.log('FAILED...', error)
      }
    )
  }

  const handleAffiliatesCPLExecute = (surveyId: string) => {
    const scriptContent = `
        (function () {
          var surveyId = '${surveyId}'
          var VARemoteLoadOptions = {
            whiteLabel: { id: 8, siteId: 2790, domain: 't.adotone.com' }, conversion: true,
          conversionData: { step: 'default', adv_uniq_id: surveyId },
        locale: "en-US", mkt: true
            };
            (function (c, o, n, v, e, r, l, y) {
            c['VARemoteLoadOptions'] = e; r = o.createElement(n), l = o.getElementsByTagName(n)[0]; r.async = 1; r.src = v; l.parentNode.insertBefore(r, l);
            })(window, document, 'script', 'https://cdn.adotone.com/javascripts/va.js', VARemoteLoadOptions);
          })();
    `
    const newScriptElement = document.createElement('script')
    newScriptElement.text = scriptContent
    document.body.appendChild(newScriptElement)
  }
  const handleClear = () => {
    form.resetFields()
  }

  const handleSubmit = () => {
    trackCustomEvent({
      category: 'contact',
      action: 'click',
      label: 'form',
      value: 1
    })

    form
      .validateFields()
      .then(fieldsValue => {
        setIsSubmitting(true)
        create({
          variables: {
            payload: getPayloadFromFieldsValue(fieldsValue as SurveyFormFields)
          },
          update: async (cache, { data }) => {
            await message.loading('請稍候...', 1)
            if (data?.createComment) {
              handleClear()
              setIsSubmitting(false)
              sendEmail()
              handleAffiliatesCPLExecute(data?.createComment?.id)
              message.success('表單傳送成功')
            }
          }
        })
      })
      .catch(({ errorFields }) => {
        setIsSubmitting(false)
        form.scrollToField(errorFields[0].name)
      })
  }

  const contentLabel = formContentLabel ?? formatMessage({ id: 'form.content' })

  const [email, setEmail] = useState('')

  const consoleClinic = e => {
    const activeClinic = e.target.value
    setEmail(activeClinic)
  }

  return (
    <Wrapper id='footer_form'>
      <Form form={form} layout='vertical'>
        <GridLayout templateColumns={['100%', 'repeat(2, 1fr)']} columnGap={[`${space.s * 3}px`]}>
          <FormItem name='name' label={formatMessage({ id: 'form.name' })} rules={[{ required: true, message: '必填欄位' }]}>
            <Input type='text' name='name' onChange={updateForm} value={notifyEmailForm.name} />
          </FormItem>
          <FormItem name='location' label={formatMessage({ id: 'form.location' })} rules={[{ required: true, message: '必填欄位' }]}>
            <ClinicSelect onChange={consoleClinic} />
          </FormItem>
        </GridLayout>
        <FormItem
          name='phone'
          label={formatMessage({ id: 'form.phone' })}
          rules={[
            { required: true, message: '必填欄位' },
            {
              /** 只能有數字，不超10碼 */
              pattern: /^[0-9]\d{0,9}$/,
              message: '您輸入的電話格式錯誤'
            }
          ]}
        >
          <Input type='text' name='phone' onChange={updateForm} value={notifyEmailForm.phone} />
        </FormItem>
        <FormItem name='line' label={formatMessage({ id: 'form.line' })} rules={[{ required: true, message: '必填欄位' }]}>
          <Input type='text' name='line' onChange={updateForm} value={notifyEmailForm.line} />
        </FormItem>
        <FormItem name='content' label={contentLabel} rules={[{ required: true, message: '必填欄位' }]}>
          <TextArea rows={3} name='content' onChange={updateForm} value={notifyEmailForm.content} />
        </FormItem>
      </Form>
      <ButtonWrapper>
        <Button label={formatMessage({ id: 'form.submit' })} loading={isSubmitting} onClick={handleSubmit} type='primary' btnSquareColor={LightGrey} />
        <Caption>送出後，客服將在三天內與您聯絡</Caption>
      </ButtonWrapper>
      <EmailNotifyFormWrapper>
        <EmailNotifyForm
          name={notifyEmailForm.name}
          location={email}
          content={notifyEmailForm.content}
          line={notifyEmailForm.line}
          phone={notifyEmailForm.phone}
          updateForm={updateForm}
        />
      </EmailNotifyFormWrapper>
    </Wrapper>
  )
}

export default ContactSurvey
