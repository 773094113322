const Primary = '#ad5b9c'
const LightGrey = '#f5f6fa'
const Secondary = '#ffc3f2'
const PrimaryLight = '#ffe1f9'
const PrimaryDark = '#705a72'
const DefaultText = 'rgba(0, 0, 0, 0.65)'
const SecondaryText = 'rgba(0, 0, 0, 0.67)'
const EmphasizeText = 'rgba(0, 0, 0, 0.85)'
const LightText = '#fef5fc'
const White = '#ffffff'

export { Primary, LightGrey, Secondary, PrimaryLight, PrimaryDark, DefaultText, SecondaryText, EmphasizeText, LightText, White }
