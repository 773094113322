import { IntlFormatters } from 'gatsby-plugin-intl'

import { NavInfo } from '.'

type GetNavInfoList = (formatMessage: IntlFormatters['formatMessage']) => NavInfo[]

const getNavInfoList: GetNavInfoList = formatMessage => [
  {
    /** 診所介紹 */
    label: formatMessage({ id: 'nav.introduction' }),
    path: '/',
    subMenuList: [
      {
        /** 診所列表 */
        label: formatMessage({ id: 'nav.clinic' }),
        path: '/clinic'
      },
      {
        /** 醫療團隊 */
        label: formatMessage({ id: 'nav.team' }),
        path: '/team'
      }
    ]
  },
  {
    /** 齒顎矯正 */
    label: formatMessage({ id: 'nav.orthodontics' }),
    path: '',
    subMenuList: [
      {
        /** 隱形矯正 */
        label: formatMessage({ id: 'nav.digital' }),
        path: '/service/digital'
      },
      {
        /** 傳統矯正 */
        label: formatMessage({ id: 'nav.lock' }),
        path: '/service/orthodontics'
      },
      {
        /** 兒童矯正 */
        label: formatMessage({ id: 'nav.childOrtho' }),
        path: '/service/childOrtho'
      },
      {
        /** 透明維持器 */
        label: formatMessage({ id: 'nav.retainer' }),
        path: '/service/retainer'
      }
    ]
  },
  {
    /** 缺牙重建 */
    label: formatMessage({ id: 'nav.reconstruction' }),
    path: '',
    subMenuList: [
      {
        /** 人工植牙 */
        label: formatMessage({ id: 'nav.dentalImplant' }),
        path: '/service/dentalImplant'
      },
      {
        /** BPS吸附式全口假牙 */
        label: formatMessage({ id: 'nav.bpsCompleteDenture' }),
        path: '/service/bpsCompleteDenture'
      },
      {
        /** 止鼾牙套 */
        label: formatMessage({ id: 'nav.snore' }),
        path: '/service/snore'
      }
    ]
  },
  {
    /** 全瓷美學 */
    label: formatMessage({ id: 'nav.allPorcelain' }),
    path: '',
    subMenuList: [
      {
        /** 假牙贋復 */
        label: formatMessage({ id: 'nav.crown' }),
        path: '/service/restorative'
      },
      {
        /** 職人瓷貼片 */
        label: formatMessage({ id: 'nav.veneers' }),
        path: '/service/veneers'
      }
    ]
  },
  {
    /** 牙齒美白 */
    label: formatMessage({ id: 'nav.teethWhitening' }),
    path: '',
    subMenuList: [
      {
        /** 冷光美白 */
        label: formatMessage({ id: 'nav.coldLight' }),
        path: '/service/teethWhitening#cold-light'
      },
      {
        /** 居家美白 */
        label: formatMessage({ id: 'nav.home' }),
        path: '/service/teethWhitening#domestic'
      },
      {
        /** 噴砂美白 */
        label: formatMessage({ id: 'nav.sand' }),
        path: '/service/teethWhitening#sandblast'
      }
    ]
  },
  {
    /** 其他診療項目 */
    label: formatMessage({ id: 'nav.otherServices' }),
    path: '',
    subMenuList: [
      {
        /** 家庭牙科 */
        label: formatMessage({ id: 'nav.dentalTreatment' }),
        path: '/service/dentalTreatment'
      },
      {
        /** 牙周治療 */
        label: formatMessage({ id: 'nav.periodontal' }),
        path: '/service/periodontal'
      },
      {
        /** 兒童牙醫 */
        label: formatMessage({ id: 'nav.child' }),
        path: '/service/child'
      },
      {
        /** 幼兒舒敏 */
        label: formatMessage({ id: 'nav.allergy' }),
        path: '/service/allergy'
      }
    ]
  },
  {
    /** 最新衛教 */
    label: formatMessage({ id: 'nav.news' }),
    path: '/news',
    subMenuList: []
  },
  {
    /** 口碑見證 */
    label: formatMessage({ id: 'nav.case' }),
    path: '/case',
    subMenuList: []
  }
]

export default getNavInfoList
