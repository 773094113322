import { IntlFormatters, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import { Primary } from '../../constants/newColor'

interface SitePathProps {
  title: string
  url: string
}
type GetSitePathList = (formatMessage: IntlFormatters['formatMessage']) => SitePathProps[]

const GetSitePath: GetSitePathList = formatMessage => [
  {
    title: formatMessage({ id: 'footer.sitePath.clinic' }),
    url: '/clinic'
  },
  {
    title: formatMessage({ id: 'footer.sitePath.team' }),
    url: '/team'
  },
  {
    title: formatMessage({ id: 'footer.sitePath.service' }),
    url: '/service/digital'
  },
  {
    title: formatMessage({ id: 'footer.sitePath.news' }),
    url: '/news'
  },
  {
    title: formatMessage({ id: 'footer.sitePath.case' }),
    url: '/case'
  }
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: ${space.l}px;
`
const Title = styled.a`
  width: fit-content;
  color: ${Primary};
  text-align: right;
  margin-bottom: ${space.m}px;
  &:hover {
    color: ${Primary};
    filter: opacity(65%);
  }
`

const SitePath = () => {
  const { formatMessage } = useIntl()
  const InfoList = GetSitePath(formatMessage)
  return (
    <Wrapper>
      {InfoList.map(info => (
        <Title href={info.url} key={info.title}>
          {info.title}
        </Title>
      ))}
    </Wrapper>
  )
}

export default SitePath
