import { Grid } from '@chakra-ui/core'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import TopLine from '../../assets/images/footer_topLine.svg'
import LogoSquare from '../../assets/images/logo_square.svg'
import { space } from '../../constants/length'
import { LightGrey } from '../../constants/newColor'
import { viewport } from '../../constants/viewport'
import ContactSurvey from '../ContactSurvey'
import ContactInfo from './ContactInfo'
import Copyright from './Copyright'
import SiteLink from './SiteLink'

const FooterWrapper = styled.div`
  background-color: ${LightGrey};
`
const Container = styled.div`
  margin: 0 auto;
  max-width: 1294px;
`

const SiteLinkWrapper = styled.div`
  background-image: url(${TopLine});
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const Img = styled.img`
  width: 200px;
`

const Footer = () => {
  const { formatMessage } = useIntl()
  return (
    <FooterWrapper>
      <Container>
        <Grid templateColumns={['unset', `3fr 4fr`]} templateRows={[`1fr, 2fr`, 'unset']} p={[0, `${space.s}`]}>
          <Grid templateRows={['4fr 3fr']} templateColumns='unset' paddingLeft={[0, `unset`, `${space.s}`]} paddingTop={[`${space.m}`, 0]}>
            <Grid templateColumns={['unset', `repeat(2, 1fr)`]} justifyItems={['center', 'unset']} alignItems={['center']}>
              <Img src={LogoSquare} alt={formatMessage({ id: 'footer.logoAlt' })} />
              <Grid alignSelf={['end']}>
                <SiteLinkWrapper>
                  <SiteLink />
                </SiteLinkWrapper>
              </Grid>
            </Grid>
            <Grid alignSelf={['center', 'start']} justifySelf={['center', 'unset']}>
              <ContactInfo />
            </Grid>
          </Grid>
          <Grid marginLeft={[0, `${space.m}`, `${space.xl}`]}>
            <ContactSurvey />
          </Grid>
        </Grid>
      </Container>
      <Copyright copyright={formatMessage({ id: 'footer.copyright' })} />
    </FooterWrapper>
  )
}

export default Footer
