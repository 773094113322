import { Link } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import downDefaultIcon from '../../assets/images/down_default.svg'
import downPrimaryIcon from '../../assets/images/down_primary.svg'
import { space } from '../../constants/length'
import { Primary, White } from '../../constants/newColor'
import { textSize } from '../../constants/text'
import { hexToRGBA } from '../../utils/color'
import { NavInfo, SwitchableProps } from '.'

const activeStyleLabelAndIcon = css`
  border-bottom: 1px solid ${Primary};
`
const baseStyleLabelAndIcon = css`
  border-bottom: 1px solid transparent;
`

const Label = styled.div`
  font-size: ${textSize.body}px;
`
const LabelAndIconWrapper = styled.div<SwitchableProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${space.s}px 0;
  width: 100%;

  ${props => (props.isActive ? activeStyleLabelAndIcon : baseStyleLabelAndIcon)}

  &:hover {
    opacity: 0.8;
  }

  img {
    margin-left: ${space.s}px;
  }
`

const activeStyleItem = css`
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 50%;
    height: 1.5px;
    border-radius: 4px;
    background-color: ${hexToRGBA(White, 0.8)};
    position: absolute;
    bottom: 30%;
  }
`

const DownIcon = styled.img`
  width: ${space.m}px;
  height: ${space.m}px;
`

const DesktopMenuItemWrapper = styled.div<SwitchableProps>`
  align-items: center;
  color: ${White};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 ${space.xs}px;
  ${props => props.isActive && activeStyleItem}
`

const LinkWrapper = styled(Link)`
  height: 100%;
`

type DesktopMenuItemLinkProps = Pick<NavInfo, 'label' | 'path'> &
  SwitchableProps & {
    showArrow?: boolean
  }

type DesktopMenuItemProps = Omit<DesktopMenuItemLinkProps, 'path'>

export const DesktopMenuItem = (props: DesktopMenuItemProps) => {
  const { isActive = false, label, showArrow = false } = props
  const [isHover, setIsHover] = useState(false)

  return (
    <DesktopMenuItemWrapper isActive={isActive}>
      <LabelAndIconWrapper isActive={isActive} onMouseLeave={() => setIsHover(false)} onMouseOver={() => setIsHover(true)}>
        <Label>{label}</Label>
        {showArrow && <DownIcon src={isActive || isHover ? downPrimaryIcon : downDefaultIcon} alt='' />}
      </LabelAndIconWrapper>
    </DesktopMenuItemWrapper>
  )
}

export const DesktopMenuItemLink = (props: DesktopMenuItemLinkProps) => {
  const { path, ...restProps } = props

  return (
    <LinkWrapper to={path}>
      <DesktopMenuItem {...restProps} />
    </LinkWrapper>
  )
}
