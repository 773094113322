import { PageRendererProps } from 'gatsby'
import { any, equals, includes, isEmpty, length, map, split } from 'ramda'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import { LocationContext } from '../Layout'
import { DesktopMenuItemLink } from './DesktopMenuItem'
import DesktopMenuItemWithSubMenu from './DesktopMenuItemWithSubMenu'
import { NavInfo, NavMenu } from '.'

const getRootPathnameFromNavInfo = (navInfo: NavMenu) => {
  return split('#', navInfo.path)[0]
}

export const isActiveMenu = (navInfo: NavInfo, pathname: string) => {
  if (!isEmpty(navInfo.subMenuList)) {
    return any((navInfo: NavMenu) => equals(getRootPathnameFromNavInfo(navInfo), pathname), navInfo.subMenuList)
  }
  return includes(navInfo.path, pathname)
}

const MenuList = styled.div`
  display: flex;
  height: 100%;
  & > * {
    margin-right: ${space.l}px;
  }
`

interface DesktopMenuListProps {
  navInfoList: NavInfo[]
}

const DesktopMenuList = (props: DesktopMenuListProps) => {
  const { navInfoList } = props

  const location = useContext<PageRendererProps['location'] | undefined>(LocationContext)

  return (
    <MenuList>
      {map(navInfo => {
        const isActive = location ? isActiveMenu(navInfo, location.pathname) : false
        const hasSubMenu = length(navInfo.subMenuList) !== 0
        return hasSubMenu ? (
          <DesktopMenuItemWithSubMenu isActive={isActive} key={navInfo.label} label={navInfo.label} subMenuList={navInfo.subMenuList} />
        ) : (
          <DesktopMenuItemLink isActive={isActive} key={navInfo.label} label={navInfo.label} path={navInfo.path} />
        )
      }, navInfoList)}
    </MenuList>
  )
}

export default DesktopMenuList
