export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type Account = Node & {
  __typename?: 'Account';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  username: Scalars['String'];
};

export type AccountInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type Banners = Node & {
  __typename?: 'Banners';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 橫幅圖片 */
  image: Scalars['String'];
  /** 圖片說明 */
  description?: Maybe<Scalars['String']>;
  /** 連結頁面 */
  link?: Maybe<Scalars['String']>;
  /** 啟用 */
  active?: Maybe<Scalars['Boolean']>;
  /** 排列順序 */
  order?: Maybe<Scalars['Int']>;
};

export type BannersQuery = {
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type Case = Node & {
  __typename?: 'Case';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 分類 */
  category: CaseCategory;
  /** 診所 */
  location?: Maybe<Location>;
  /** 標題 */
  title: Scalars['String'];
  /** 頁面標題 */
  pageTitle?: Maybe<Scalars['String']>;
  /** Meta描述 */
  description?: Maybe<Scalars['String']>;
  /** 自訂網址 */
  alias?: Maybe<Scalars['String']>;
  /** 作者 */
  name?: Maybe<Scalars['String']>;
  /** 醫師 */
  doctor?: Maybe<Scalars['String']>;
  /** 發布日期 */
  publishedDate: Scalars['Date'];
  /** 縮圖 */
  thumbnailImage: Scalars['String'];
  /** 圖片 */
  images?: Maybe<Array<Scalars['String']>>;
  /** 內文 */
  content?: Maybe<Scalars['String']>;
  /** 影片 */
  video?: Maybe<Scalars['String']>;
};

export enum CaseCategory {
  /** 矯正 */
  Orthodontics = 'ORTHODONTICS',
  /** 美白 */
  Whitening = 'WHITENING',
  /** 貼片 */
  Patch = 'PATCH',
  /** 其他 */
  Others = 'OTHERS'
}

export type CasesQuery = {
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Array<CaseCategory>>;
  location?: Maybe<Array<Maybe<Location>>>;
};

export type Comment = Node & {
  __typename?: 'Comment';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  status?: Maybe<CommentStatus>;
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  content?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
};

export type CommentsQuery = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<Location>>>;
  internalNote?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<CommentStatus>>>;
};

export enum CommentStatus {
  /** 待處理 */
  Pending = 'PENDING',
  /** 處理中 */
  InProcess = 'IN_PROCESS',
  /** 已完成 */
  Evaluated = 'EVALUATED'
}

export type CreateBannersInput = {
  image: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateCaseInput = {
  category: CaseCategory;
  location?: Maybe<Location>;
  title: Scalars['String'];
  pageTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
  publishedDate: Scalars['Date'];
  thumbnailImage: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type CreateCommentInput = {
  status?: Maybe<CommentStatus>;
  name: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  content?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
};

export type CreateDoctorsInput = {
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  location: Array<Maybe<Location>>;
  title?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  elite?: Maybe<Scalars['Boolean']>;
  commend?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateNewsInput = {
  category: NewsCategory;
  title: Scalars['String'];
  pageTitle?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  publishedDate: Scalars['Date'];
  images?: Maybe<Array<Scalars['String']>>;
  thumbnailImage: Scalars['String'];
  thumbnailAlt?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};


export type Doctors = Node & {
  __typename?: 'Doctors';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 醫師 */
  name: Scalars['String'];
  /** 圖片 */
  image?: Maybe<Scalars['String']>;
  /** 服務診所 */
  location: Array<Location>;
  /** 專科領域 */
  title?: Maybe<Scalars['String']>;
  /** 主治專長 */
  strength?: Maybe<Scalars['String']>;
  /** 學經歷 */
  experience?: Maybe<Scalars['String']>;
  /** 菁英團隊 */
  elite?: Maybe<Scalars['Boolean']>;
  /** 患者好評 */
  commend?: Maybe<Scalars['String']>;
  /** 排列順序 */
  order?: Maybe<Scalars['Int']>;
};

export type DoctorsInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<Location>>>;
  title?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  elite?: Maybe<Scalars['Boolean']>;
  commend?: Maybe<Scalars['String']>;
};

export type DoctorsQuery = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  elite?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Location>>;
};

export enum Location {
  /** 古亭蒔穗 */
  Guting = 'GUTING',
  /** 新竹橙蒔 */
  Hsinchu = 'HSINCHU',
  /** 台中日蒔 */
  Taichung = 'TAICHUNG',
  /** 台南蒔光 */
  Tainan = 'TAINAN',
  /** 日亞美 */
  Taipei = 'TAIPEI'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAccount?: Maybe<Account>;
  createBanners?: Maybe<Banners>;
  createCase?: Maybe<Case>;
  createComment?: Maybe<Comment>;
  createDoctors?: Maybe<Doctors>;
  createNews?: Maybe<News>;
  login?: Maybe<Response>;
  logout?: Maybe<Response>;
  removeAccount?: Maybe<Account>;
  removeBanners?: Maybe<Banners>;
  removeCase?: Maybe<Case>;
  removeComment?: Maybe<Comment>;
  removeDoctors?: Maybe<Doctors>;
  removeNews?: Maybe<News>;
  updateBanners?: Maybe<Banners>;
  updateCase?: Maybe<Case>;
  updateComment?: Maybe<Comment>;
  updateDoctors?: Maybe<Doctors>;
  updateNews?: Maybe<News>;
  uploadFile: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  payload: AccountInput;
};


export type MutationCreateBannersArgs = {
  payload: CreateBannersInput;
};


export type MutationCreateCaseArgs = {
  payload: CreateCaseInput;
};


export type MutationCreateCommentArgs = {
  payload: CreateCommentInput;
};


export type MutationCreateDoctorsArgs = {
  payload: CreateDoctorsInput;
};


export type MutationCreateNewsArgs = {
  payload: CreateNewsInput;
};


export type MutationLoginArgs = {
  input: AccountInput;
};


export type MutationRemoveAccountArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveBannersArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCaseArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDoctorsArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveNewsArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBannersArgs = {
  id: Scalars['ID'];
  payload: UpdateBannersInput;
};


export type MutationUpdateCaseArgs = {
  id: Scalars['ID'];
  payload: UpdateCaseInput;
};


export type MutationUpdateCommentArgs = {
  id: Scalars['ID'];
  payload: UpdateCommentInput;
};


export type MutationUpdateDoctorsArgs = {
  id: Scalars['ID'];
  payload: UpdateDoctorsInput;
};


export type MutationUpdateNewsArgs = {
  id: Scalars['ID'];
  payload: UpdateNewsInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type News = Node & {
  __typename?: 'News';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 分類 */
  category: NewsCategory;
  /** 文章標題 */
  title: Scalars['String'];
  /** 頁面標題 */
  pageTitle?: Maybe<Scalars['String']>;
  /** 自訂網址 */
  alias?: Maybe<Scalars['String']>;
  /** Meta描述 */
  description?: Maybe<Scalars['String']>;
  /** 發布日期 */
  publishedDate: Scalars['Date'];
  /** 圖片 */
  images?: Maybe<Array<Scalars['String']>>;
  /** 首圖 */
  thumbnailImage?: Maybe<Scalars['String']>;
  /** 首圖替代文字 */
  thumbnailAlt?: Maybe<Scalars['String']>;
  /** 內文 */
  content?: Maybe<Scalars['String']>;
  /** 影片 */
  video?: Maybe<Scalars['String']>;
};

export enum NewsCategory {
  /** 美學 */
  Beauty = 'BEAUTY',
  /** 缺牙 */
  Missing = 'MISSING',
  /** 牙痛 */
  Toothache = 'TOOTHACHE',
  /** 兒童 */
  Children = 'CHILDREN'
}

export type NewsQuery = {
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Array<NewsCategory>>;
  q?: Maybe<Scalars['String']>;
};

export type Node = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type PagedAccount = {
  __typename?: 'PagedAccount';
  docs?: Maybe<Array<Account>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedBanners = {
  __typename?: 'PagedBanners';
  docs?: Maybe<Array<Banners>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  sort?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
};

export type PagedCase = {
  __typename?: 'PagedCase';
  docs?: Maybe<Array<Case>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedComment = {
  __typename?: 'PagedComment';
  docs?: Maybe<Array<Comment>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedDoctors = {
  __typename?: 'PagedDoctors';
  docs?: Maybe<Array<Doctors>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  sort?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
};

export type PagedNews = {
  __typename?: 'PagedNews';
  docs?: Maybe<Array<News>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  banners?: Maybe<Banners>;
  bannersList?: Maybe<PagedBanners>;
  case?: Maybe<Case>;
  cases?: Maybe<PagedCase>;
  checkIsLoggedIn?: Maybe<Response>;
  comment?: Maybe<Comment>;
  comments?: Maybe<PagedComment>;
  doctors?: Maybe<Doctors>;
  doctorsList?: Maybe<PagedDoctors>;
  news?: Maybe<News>;
  newsList?: Maybe<PagedNews>;
  node?: Maybe<Node>;
  nodes: Array<Node>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryBannersArgs = {
  id: Scalars['ID'];
};


export type QueryBannersListArgs = {
  query?: Maybe<BannersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryCaseArgs = {
  id: Scalars['ID'];
};


export type QueryCasesArgs = {
  query?: Maybe<CasesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  query?: Maybe<CommentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryDoctorsArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorsListArgs = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryNewsArgs = {
  id: Scalars['ID'];
};


export type QueryNewsListArgs = {
  query?: Maybe<NewsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type Response = {
  __typename?: 'Response';
  data?: Maybe<ResponseData>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResponseData = {
  __typename?: 'ResponseData';
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
};

export type UpdateBannersInput = {
  image: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateCaseInput = {
  category?: Maybe<CaseCategory>;
  location?: Maybe<Location>;
  title?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['Date']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  status?: Maybe<CommentStatus>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  internalNote?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type UpdateDoctorsInput = {
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<Location>>>;
  title?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  elite?: Maybe<Scalars['Boolean']>;
  commend?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateNewsInput = {
  category?: Maybe<NewsCategory>;
  title?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['Date']>;
  images?: Maybe<Array<Scalars['String']>>;
  thumbnailImage?: Maybe<Scalars['String']>;
  thumbnailAlt?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};


export type CreateCommentMutationVariables = Exact<{
  payload: CreateCommentInput;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'name' | 'phone' | 'email' | 'content'>
  )> }
);

export type CreateSurveyMutationVariables = Exact<{
  payload: CreateCommentInput;
}>;


export type CreateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'name' | 'phone' | 'line' | 'location' | 'content' | 'status'>
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message' | 'success'>
    & { data?: Maybe<(
      { __typename?: 'ResponseData' }
      & Pick<ResponseData, 'id' | 'username'>
    )> }
  )> }
);

export type DashboardDoctorSelectListQueryVariables = Exact<{
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardDoctorSelectListQuery = (
  { __typename?: 'Query' }
  & { doctorsList?: Maybe<(
    { __typename?: 'PagedDoctors' }
    & Pick<PagedDoctors, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Doctors' }
      & Pick<Doctors, 'id' | 'created' | 'location' | 'title' | 'name' | 'elite' | 'image'>
    )>> }
  )> }
);

export type BannerListQueryVariables = Exact<{
  query?: Maybe<BannersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type BannerListQuery = (
  { __typename?: 'Query' }
  & { bannersList?: Maybe<(
    { __typename?: 'PagedBanners' }
    & Pick<PagedBanners, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Banners' }
      & Pick<Banners, 'id' | 'image' | 'description' | 'link' | 'active' | 'order'>
    )>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  input: AccountInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message' | 'success'>
    & { data?: Maybe<(
      { __typename?: 'ResponseData' }
      & Pick<ResponseData, 'id' | 'username'>
    )> }
  )> }
);

export type CheckIsLoggedInWhenInLoginPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckIsLoggedInWhenInLoginPageQuery = (
  { __typename?: 'Query' }
  & { checkIsLoggedIn?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message' | 'success'>
  )> }
);

export type CaseListQueryVariables = Exact<{
  query?: Maybe<CasesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type CaseListQuery = (
  { __typename?: 'Query' }
  & { cases?: Maybe<(
    { __typename?: 'PagedCase' }
    & Pick<PagedCase, 'total' | 'page'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Case' }
      & Pick<Case, 'id' | 'category' | 'location' | 'content' | 'created' | 'alias' | 'publishedDate' | 'name' | 'doctor' | 'title' | 'thumbnailImage'>
    )>> }
  )> }
);

export type CaseAndCaseListQueryVariables = Exact<{
  id: Scalars['ID'];
  query?: Maybe<CasesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type CaseAndCaseListQuery = (
  { __typename?: 'Query' }
  & { case?: Maybe<(
    { __typename?: 'Case' }
    & Pick<Case, 'id' | 'content' | 'publishedDate' | 'images' | 'name' | 'title' | 'alias' | 'pageTitle' | 'description' | 'doctor' | 'location' | 'thumbnailImage' | 'video' | 'category'>
  )>, cases?: Maybe<(
    { __typename?: 'PagedCase' }
    & { docs?: Maybe<Array<(
      { __typename?: 'Case' }
      & Pick<Case, 'id' | 'publishedDate' | 'name' | 'doctor' | 'location' | 'alias' | 'title' | 'thumbnailImage' | 'category'>
    )>> }
  )> }
);

export type CreateBannerMutationVariables = Exact<{
  payload: CreateBannersInput;
}>;


export type CreateBannerMutation = (
  { __typename?: 'Mutation' }
  & { createBanners?: Maybe<(
    { __typename?: 'Banners' }
    & Pick<Banners, 'id'>
  )> }
);

export type DashboardBannerListQueryVariables = Exact<{
  query?: Maybe<BannersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardBannerListQuery = (
  { __typename?: 'Query' }
  & { bannersList?: Maybe<(
    { __typename?: 'PagedBanners' }
    & Pick<PagedBanners, 'page' | 'limit' | 'sort' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Banners' }
      & Pick<Banners, 'id' | 'image' | 'link' | 'description' | 'active' | 'order'>
    )>> }
  )> }
);

export type DashboardBannerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DashboardBannerQuery = (
  { __typename?: 'Query' }
  & { banners?: Maybe<(
    { __typename?: 'Banners' }
    & Pick<Banners, 'id' | 'image' | 'description' | 'link' | 'active' | 'order'>
  )> }
);

export type UpdateBannerMutationVariables = Exact<{
  id: Scalars['ID'];
  payload: UpdateBannersInput;
}>;


export type UpdateBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateBanners?: Maybe<(
    { __typename?: 'Banners' }
    & Pick<Banners, 'id'>
  )> }
);

export type RemoveBannerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveBannerMutation = (
  { __typename?: 'Mutation' }
  & { removeBanners?: Maybe<(
    { __typename?: 'Banners' }
    & Pick<Banners, 'id'>
  )> }
);

export type CreateCaseMutationVariables = Exact<{
  payload: CreateCaseInput;
}>;


export type CreateCaseMutation = (
  { __typename?: 'Mutation' }
  & { createCase?: Maybe<(
    { __typename?: 'Case' }
    & Pick<Case, 'id'>
  )> }
);

export type DashboardCaseListQueryVariables = Exact<{
  query?: Maybe<CasesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardCaseListQuery = (
  { __typename?: 'Query' }
  & { cases?: Maybe<(
    { __typename?: 'PagedCase' }
    & Pick<PagedCase, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Case' }
      & Pick<Case, 'id' | 'category' | 'location' | 'title' | 'name' | 'doctor' | 'publishedDate' | 'thumbnailImage'>
    )>> }
  )> }
);

export type DashboardCaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DashboardCaseQuery = (
  { __typename?: 'Query' }
  & { case?: Maybe<(
    { __typename?: 'Case' }
    & Pick<Case, 'id' | 'category' | 'location' | 'title' | 'pageTitle' | 'description' | 'alias' | 'name' | 'doctor' | 'content' | 'publishedDate' | 'images' | 'thumbnailImage' | 'video'>
  )> }
);

export type UpdateCaseMutationVariables = Exact<{
  id: Scalars['ID'];
  payload: UpdateCaseInput;
}>;


export type UpdateCaseMutation = (
  { __typename?: 'Mutation' }
  & { updateCase?: Maybe<(
    { __typename?: 'Case' }
    & Pick<Case, 'id'>
  )> }
);

export type RemoveCaseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCaseMutation = (
  { __typename?: 'Mutation' }
  & { removeCase?: Maybe<(
    { __typename?: 'Case' }
    & Pick<Case, 'id'>
  )> }
);

export type DashboardCommentListQueryVariables = Exact<{
  query?: Maybe<CommentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardCommentListQuery = (
  { __typename?: 'Query' }
  & { comments?: Maybe<(
    { __typename?: 'PagedComment' }
    & Pick<PagedComment, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'status' | 'created' | 'name' | 'phone' | 'line' | 'location' | 'internalNote' | 'content'>
    )>> }
  )> }
);

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & { removeComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
  )> }
);

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  payload: UpdateCommentInput;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'status' | 'internalNote'>
  )> }
);

export type CreateDoctorMutationVariables = Exact<{
  payload: CreateDoctorsInput;
}>;


export type CreateDoctorMutation = (
  { __typename?: 'Mutation' }
  & { createDoctors?: Maybe<(
    { __typename?: 'Doctors' }
    & Pick<Doctors, 'id'>
  )> }
);

export type DashboardDoctorListQueryVariables = Exact<{
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardDoctorListQuery = (
  { __typename?: 'Query' }
  & { doctorsList?: Maybe<(
    { __typename?: 'PagedDoctors' }
    & Pick<PagedDoctors, 'page' | 'limit' | 'sort' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Doctors' }
      & Pick<Doctors, 'id' | 'created' | 'location' | 'title' | 'name' | 'elite' | 'image' | 'order'>
    )>> }
  )> }
);

export type DashboardDoctorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DashboardDoctorQuery = (
  { __typename?: 'Query' }
  & { doctors?: Maybe<(
    { __typename?: 'Doctors' }
    & Pick<Doctors, 'id' | 'name' | 'image' | 'location' | 'title' | 'strength' | 'experience' | 'elite' | 'commend' | 'order'>
  )> }
);

export type UpdateDoctorMutationVariables = Exact<{
  id: Scalars['ID'];
  payload: UpdateDoctorsInput;
}>;


export type UpdateDoctorMutation = (
  { __typename?: 'Mutation' }
  & { updateDoctors?: Maybe<(
    { __typename?: 'Doctors' }
    & Pick<Doctors, 'id'>
  )> }
);

export type RemoveDoctorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveDoctorMutation = (
  { __typename?: 'Mutation' }
  & { removeDoctors?: Maybe<(
    { __typename?: 'Doctors' }
    & Pick<Doctors, 'id'>
  )> }
);

export type CheckIsLoggedInWhenInDashboardIndexQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckIsLoggedInWhenInDashboardIndexQuery = (
  { __typename?: 'Query' }
  & { checkIsLoggedIn?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message' | 'success'>
  )> }
);

export type CreateNewsMutationVariables = Exact<{
  payload: CreateNewsInput;
}>;


export type CreateNewsMutation = (
  { __typename?: 'Mutation' }
  & { createNews?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id'>
  )> }
);

export type DashboardNewsListQueryVariables = Exact<{
  query?: Maybe<NewsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DashboardNewsListQuery = (
  { __typename?: 'Query' }
  & { newsList?: Maybe<(
    { __typename?: 'PagedNews' }
    & Pick<PagedNews, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'category' | 'title' | 'publishedDate'>
    )>> }
  )> }
);

export type DashboardNewsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DashboardNewsQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'category' | 'title' | 'alias' | 'pageTitle' | 'description' | 'content' | 'publishedDate' | 'images' | 'thumbnailImage' | 'thumbnailAlt' | 'video'>
  )> }
);

export type UpdateNewsMutationVariables = Exact<{
  id: Scalars['ID'];
  payload: UpdateNewsInput;
}>;


export type UpdateNewsMutation = (
  { __typename?: 'Mutation' }
  & { updateNews?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id'>
  )> }
);

export type RemoveNewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveNewsMutation = (
  { __typename?: 'Mutation' }
  & { removeNews?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id'>
  )> }
);

export type NewsListQueryVariables = Exact<{
  query?: Maybe<NewsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type NewsListQuery = (
  { __typename?: 'Query' }
  & { newsList?: Maybe<(
    { __typename?: 'PagedNews' }
    & Pick<PagedNews, 'total' | 'page'>
    & { docs?: Maybe<Array<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'images' | 'thumbnailImage' | 'thumbnailAlt' | 'title' | 'description' | 'publishedDate' | 'alias'>
    )>> }
  )> }
);

export type NewsAndNewsListQueryVariables = Exact<{
  id: Scalars['ID'];
  query?: Maybe<NewsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type NewsAndNewsListQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'pageTitle' | 'description' | 'content' | 'publishedDate' | 'alias' | 'images' | 'thumbnailAlt' | 'title' | 'video'>
  )>, newsList?: Maybe<(
    { __typename?: 'PagedNews' }
    & { docs?: Maybe<Array<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'publishedDate' | 'title' | 'alias' | 'thumbnailImage' | 'thumbnailAlt' | 'description'>
    )>> }
  )> }
);

export type DoctorListQueryVariables = Exact<{
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type DoctorListQuery = (
  { __typename?: 'Query' }
  & { doctorsList?: Maybe<(
    { __typename?: 'PagedDoctors' }
    & Pick<PagedDoctors, 'page' | 'limit' | 'total'>
    & { docs?: Maybe<Array<(
      { __typename?: 'Doctors' }
      & Pick<Doctors, 'id' | 'created' | 'location' | 'title' | 'name' | 'elite' | 'image' | 'strength' | 'experience' | 'commend' | 'order'>
    )>> }
  )> }
);

export type CreateCommentVariables = CreateCommentMutationVariables;
export type CreateCommentCreateComment = (NonNullable<CreateCommentMutation['createComment']>);
export type CreateSurveyVariables = CreateSurveyMutationVariables;
export type CreateSurveyCreateComment = (NonNullable<CreateSurveyMutation['createComment']>);
export type UploadFileVariables = UploadFileMutationVariables;
export type LogoutVariables = LogoutMutationVariables;
export type LogoutLogout = (NonNullable<LogoutMutation['logout']>);
export type LogoutData = (NonNullable<(NonNullable<LogoutMutation['logout']>)['data']>);
export type DashboardDoctorSelectListVariables = DashboardDoctorSelectListQueryVariables;
export type DashboardDoctorSelectListDoctorsList = (NonNullable<DashboardDoctorSelectListQuery['doctorsList']>);
export type DashboardDoctorSelectListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardDoctorSelectListQuery['doctorsList']>)['docs']>)[0]>);
export type BannerListVariables = BannerListQueryVariables;
export type BannerListBannersList = (NonNullable<BannerListQuery['bannersList']>);
export type BannerListDocs = (NonNullable<(NonNullable<(NonNullable<BannerListQuery['bannersList']>)['docs']>)[0]>);
export type LoginVariables = LoginMutationVariables;
export type LoginLogin = (NonNullable<LoginMutation['login']>);
export type LoginData = (NonNullable<(NonNullable<LoginMutation['login']>)['data']>);
export type CheckIsLoggedInWhenInLoginPageVariables = CheckIsLoggedInWhenInLoginPageQueryVariables;
export type CheckIsLoggedInWhenInLoginPageCheckIsLoggedIn = (NonNullable<CheckIsLoggedInWhenInLoginPageQuery['checkIsLoggedIn']>);
export type CaseListVariables = CaseListQueryVariables;
export type CaseListCases = (NonNullable<CaseListQuery['cases']>);
export type CaseListDocs = (NonNullable<(NonNullable<(NonNullable<CaseListQuery['cases']>)['docs']>)[0]>);
export type CaseAndCaseListVariables = CaseAndCaseListQueryVariables;
export type CaseAndCaseListCase = (NonNullable<CaseAndCaseListQuery['case']>);
export type CaseAndCaseListCases = (NonNullable<CaseAndCaseListQuery['cases']>);
export type CaseAndCaseListDocs = (NonNullable<(NonNullable<(NonNullable<CaseAndCaseListQuery['cases']>)['docs']>)[0]>);
export type CreateBannerVariables = CreateBannerMutationVariables;
export type CreateBannerCreateBanners = (NonNullable<CreateBannerMutation['createBanners']>);
export type DashboardBannerListVariables = DashboardBannerListQueryVariables;
export type DashboardBannerListBannersList = (NonNullable<DashboardBannerListQuery['bannersList']>);
export type DashboardBannerListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardBannerListQuery['bannersList']>)['docs']>)[0]>);
export type DashboardBannerVariables = DashboardBannerQueryVariables;
export type DashboardBannerBanners = (NonNullable<DashboardBannerQuery['banners']>);
export type UpdateBannerVariables = UpdateBannerMutationVariables;
export type UpdateBannerUpdateBanners = (NonNullable<UpdateBannerMutation['updateBanners']>);
export type RemoveBannerVariables = RemoveBannerMutationVariables;
export type RemoveBannerRemoveBanners = (NonNullable<RemoveBannerMutation['removeBanners']>);
export type CreateCaseVariables = CreateCaseMutationVariables;
export type CreateCaseCreateCase = (NonNullable<CreateCaseMutation['createCase']>);
export type DashboardCaseListVariables = DashboardCaseListQueryVariables;
export type DashboardCaseListCases = (NonNullable<DashboardCaseListQuery['cases']>);
export type DashboardCaseListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardCaseListQuery['cases']>)['docs']>)[0]>);
export type DashboardCaseVariables = DashboardCaseQueryVariables;
export type DashboardCaseCase = (NonNullable<DashboardCaseQuery['case']>);
export type UpdateCaseVariables = UpdateCaseMutationVariables;
export type UpdateCaseUpdateCase = (NonNullable<UpdateCaseMutation['updateCase']>);
export type RemoveCaseVariables = RemoveCaseMutationVariables;
export type RemoveCaseRemoveCase = (NonNullable<RemoveCaseMutation['removeCase']>);
export type DashboardCommentListVariables = DashboardCommentListQueryVariables;
export type DashboardCommentListComments = (NonNullable<DashboardCommentListQuery['comments']>);
export type DashboardCommentListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardCommentListQuery['comments']>)['docs']>)[0]>);
export type RemoveCommentVariables = RemoveCommentMutationVariables;
export type RemoveCommentRemoveComment = (NonNullable<RemoveCommentMutation['removeComment']>);
export type UpdateCommentVariables = UpdateCommentMutationVariables;
export type UpdateCommentUpdateComment = (NonNullable<UpdateCommentMutation['updateComment']>);
export type CreateDoctorVariables = CreateDoctorMutationVariables;
export type CreateDoctorCreateDoctors = (NonNullable<CreateDoctorMutation['createDoctors']>);
export type DashboardDoctorListVariables = DashboardDoctorListQueryVariables;
export type DashboardDoctorListDoctorsList = (NonNullable<DashboardDoctorListQuery['doctorsList']>);
export type DashboardDoctorListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardDoctorListQuery['doctorsList']>)['docs']>)[0]>);
export type DashboardDoctorVariables = DashboardDoctorQueryVariables;
export type DashboardDoctorDoctors = (NonNullable<DashboardDoctorQuery['doctors']>);
export type UpdateDoctorVariables = UpdateDoctorMutationVariables;
export type UpdateDoctorUpdateDoctors = (NonNullable<UpdateDoctorMutation['updateDoctors']>);
export type RemoveDoctorVariables = RemoveDoctorMutationVariables;
export type RemoveDoctorRemoveDoctors = (NonNullable<RemoveDoctorMutation['removeDoctors']>);
export type CheckIsLoggedInWhenInDashboardIndexVariables = CheckIsLoggedInWhenInDashboardIndexQueryVariables;
export type CheckIsLoggedInWhenInDashboardIndexCheckIsLoggedIn = (NonNullable<CheckIsLoggedInWhenInDashboardIndexQuery['checkIsLoggedIn']>);
export type CreateNewsVariables = CreateNewsMutationVariables;
export type CreateNewsCreateNews = (NonNullable<CreateNewsMutation['createNews']>);
export type DashboardNewsListVariables = DashboardNewsListQueryVariables;
export type DashboardNewsListNewsList = (NonNullable<DashboardNewsListQuery['newsList']>);
export type DashboardNewsListDocs = (NonNullable<(NonNullable<(NonNullable<DashboardNewsListQuery['newsList']>)['docs']>)[0]>);
export type DashboardNewsVariables = DashboardNewsQueryVariables;
export type DashboardNewsNews = (NonNullable<DashboardNewsQuery['news']>);
export type UpdateNewsVariables = UpdateNewsMutationVariables;
export type UpdateNewsUpdateNews = (NonNullable<UpdateNewsMutation['updateNews']>);
export type RemoveNewsVariables = RemoveNewsMutationVariables;
export type RemoveNewsRemoveNews = (NonNullable<RemoveNewsMutation['removeNews']>);
export type NewsListVariables = NewsListQueryVariables;
export type NewsListNewsList = (NonNullable<NewsListQuery['newsList']>);
export type NewsListDocs = (NonNullable<(NonNullable<(NonNullable<NewsListQuery['newsList']>)['docs']>)[0]>);
export type NewsAndNewsListVariables = NewsAndNewsListQueryVariables;
export type NewsAndNewsListNews = (NonNullable<NewsAndNewsListQuery['news']>);
export type NewsAndNewsListNewsList = (NonNullable<NewsAndNewsListQuery['newsList']>);
export type NewsAndNewsListDocs = (NonNullable<(NonNullable<(NonNullable<NewsAndNewsListQuery['newsList']>)['docs']>)[0]>);
export type DoctorListVariables = DoctorListQueryVariables;
export type DoctorListDoctorsList = (NonNullable<DoctorListQuery['doctorsList']>);
export type DoctorListDocs = (NonNullable<(NonNullable<(NonNullable<DoctorListQuery['doctorsList']>)['docs']>)[0]>);