import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import FormArrow from '../../assets/images/footer_formArrow.svg'
import LineImg from '../../assets/images/line_fill.svg'
import { space } from '../../constants/length'
import { Primary } from '../../constants/newColor'
import { Caption as _Caption } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import clinicInfoList from '../Clinic/Info'
import Tags from '../Tags'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${viewport.tablet - 1}px) {
    align-items: center;
  }
  @media (min-width: ${viewport.tablet}px) {
    align-items: start;

    background-image: url(${FormArrow});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto;
    padding: ${space.m}px 0 ${space.xl}px 0;
  }
`

const ClinicsLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 260px;
    margin: auto;
    padding-left: 8px;
  }
`
const Caption = styled.span`
  ${_Caption}
  display: inline-block;
  color: ${Primary};
  margin: ${space.s}px 0;
`
const tagMargin = `0 ${space.s}px ${space.s}px 0`

const ContactInfo = () => {
  const { formatMessage } = useIntl()

  const clinicList = clinicInfoList
    .flat()
    .map(clinic => clinic.clinics)
    .flat()

  return (
    <Wrapper>
      <Caption>{formatMessage({ id: 'footer.title' })}</Caption>
      <ClinicsLine>
        {clinicList.map(clinic => (
          <Tags key={clinic.lineBtnValue} type={clinic.value} title={clinic.lineBtnValue} m={tagMargin} img={LineImg} href={clinic.socialMedia_line} />
        ))}
      </ClinicsLine>
      <Caption>{formatMessage({ id: 'footer.subtitle' })}</Caption>
    </Wrapper>
  )
}

export default ContactInfo
