const mobileHeaderHeight = 64
const desktopHeaderHeight = 100

const spaceKeys = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'] as const

type SpaceKeys = typeof spaceKeys
type SpaceKey = SpaceKeys[number]
type Space = Record<SpaceKey, number>

const space: Space = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 40
}

export { desktopHeaderHeight, mobileHeaderHeight, space }
