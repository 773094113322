import { css } from 'styled-components'

import { Primary, Secondary } from './newColor'
import { viewport } from './viewport'

interface ColorProps {
  color?: string
}
type TitleProps = ColorProps

const H1 = css<TitleProps>`
  color: ${props => (props.color ? props.color : Primary)};
  font-family: 'Noto Sans TC';
  font-weight: 500;
  text-align: center;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 1.5rem; //24px
    line-height: 2.25rem; //36px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1.875rem; //30px
    line-height: 2.5rem; //40px
  }
`

type H2Props = ColorProps
const H2 = css<H2Props>`
  color: ${props => (props.color ? props.color : Primary)};
  font-family: 'Noto Sans TC';
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 1.25rem; //20px
    line-height: 2.25rem; //36px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1.5rem; //24px
    line-height: 2.25rem; //36px
  }
`

type H3Props = ColorProps
const H3 = css<H3Props>`
  color: ${props => (props.color ? props.color : Primary)};
  font-family: 'Noto Sans TC';
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 1rem; //16px
    line-height: 1.5rem; //24px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1.25rem; //20px
    line-height: 2rem; //32px
  }
`

type H4Props = ColorProps
const H4 = css<H4Props>`
  color: ${props => (props.color ? props.color : Primary)};
  font-family: 'Noto Sans TC';
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 0.875rem; //14px
    line-height: 1.25rem; //20px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1rem; //16px
    line-height: 1.5rem; //24px
  }
`

type BodyProps = ColorProps
const Body = css<BodyProps>`
  color: ${props => (props.color ? props.color : Secondary)};
  font-family: 'Noto Sans TC';
  font-weight: 300;
  text-align: center;
  margin: 0;
  padding: 0;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 1rem; //16px
    line-height: 2rem; //32px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1rem; //16px
    line-height: 2rem; //32px
  }
`

/** 備註 */
type CaptionProps = ColorProps
const Caption = css<CaptionProps>`
  color: ${props => (props.color ? props.color : Secondary)};
  font-family: 'Noto Sans TC';
  font-weight: 400;
  text-align: center;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 0.75rem; //12px
    line-height: 1.125rem; //18px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 0.875rem; //14px
    line-height: 1.125rem; //18px
  }
`

type BtnProps = ColorProps
const Btn = css<BtnProps>`
  color: ${props => (props.color ? props.color : Primary)};
  font-family: 'Noto Sans TC';
  font-weight: 400;
  text-align: center;

  /** sm */
  @media (max-width: ${viewport.desktop}px) {
    font-size: 0.75rem; //12px
    line-height: 1.5rem; //24px
  }

  /** lg */
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1rem; //16px
    line-height: 1.5rem; //24px
  }
`

type TagProps = ColorProps
const Tag = css<TagProps>`
  color: ${props => (props.color ? props.color : Secondary)};
  font-family: 'cwTeXYen';
  font-weight: 300;
  text-align: center;

  /** sm */
  @media (max-width: ${viewport.tablet}px) {
    font-size: 0.875rem; //12px
    line-height: 0.875rem; //12px
  }

  /** lg */
  @media (min-width: ${viewport.tablet + 1}px) {
    font-size: 1rem; //16px
    line-height: 1rem; //16px
  }
`

export { Body, Caption, Btn, Tag, H2, H3, H4, H1 }
