const mobileHeaderHeight = 60
const tabletHeaderHeight = 80
const desktopHeaderHeight = 100

const gray = '#585858'
const lightPurple = '#eab1d1'
const purple = '#b761a2'
const transparentBlack = 'rgba(0, 0, 0, 0.65)'
const backdropBlack = 'rgba(63, 60, 60, 0.45)'

export { desktopHeaderHeight, mobileHeaderHeight, tabletHeaderHeight, gray, lightPurple, purple, transparentBlack, backdropBlack }
