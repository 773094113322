import { map } from 'ramda'
import React, { MouseEvent } from 'react'
import styled from 'styled-components'

import { White } from '../../constants/color'
import { space } from '../../constants/length'
import { MobileNavMenuItem, MobileNavMenuItemLink } from './MobileNavMenuItem'
import { NavInfo, SwitchableProps } from '.'

const MobileNavMenuItemWithSubMenuWrapper = styled.div`
  background-color: ${White};
`
const SubMenuWrapper = styled.div`
  padding-left: ${space.l}px;
`

type MobileNavMenuItemWithSubMenuProps = SwitchableProps &
  Pick<NavInfo, 'label' | 'subMenuList'> & {
    handleCloseMenu: () => void
    isExpanded: boolean
    handleToggleSubmenu: (key: string | undefined) => void
  }

const MobileNavMenuItemWithSubMenu = (props: MobileNavMenuItemWithSubMenuProps) => {
  const { isActive, label, subMenuList, handleCloseMenu, isExpanded, handleToggleSubmenu } = props

  const handleToggle = (e: MouseEvent) => {
    if (isExpanded) {
      handleToggleSubmenu(undefined)
    } else {
      handleToggleSubmenu(label)
    }
    // 避免觸發上層的 onClick 關掉 menu
    e.stopPropagation()
  }

  return (
    <MobileNavMenuItemWithSubMenuWrapper onClick={handleToggle}>
      <MobileNavMenuItem isActive={isActive} label={label} showArrow />
      <SubMenuWrapper onClick={handleCloseMenu}>
        {isExpanded && map(subMenu => <MobileNavMenuItemLink isActive={false} key={`nav-subMenu-${subMenu.label}`} label={subMenu.label} path={subMenu.path} />, subMenuList)}
      </SubMenuWrapper>
    </MobileNavMenuItemWithSubMenuWrapper>
  )
}

export default MobileNavMenuItemWithSubMenu
