import { Select, SelectProps } from '@chakra-ui/core'
import React from 'react'
import styled from 'styled-components'

import clinicInfoList from '../components/Clinic/Info'
import { Grey } from '../constants/newColor'

const CustomizedSelect = styled(Select)`
  /** 增加權重覆寫掉 chakra 預設 style */
  && {
    background-color: ${Grey};
    text-align: left;
    height: 32px;
  }
`

type ClinicSelectProps = SelectProps

const ClinicSelect = (props: ClinicSelectProps) => {
  const { isDisabled, onChange, value } = props

  return (
    <CustomizedSelect isDisabled={isDisabled} onChange={onChange} placeholder='選擇診所' value={value} width='100%'>
      {clinicInfoList.map(clinicInfo => (
        <optgroup key={clinicInfo.area} label={clinicInfo.area}>
          {clinicInfo.clinics.map(clinic => (
            <option key={clinic.footerOptionName} value={clinic.value}>
              {clinic.footerOptionName}
            </option>
          ))}
        </optgroup>
      ))}
    </CustomizedSelect>
  )
}

export default ClinicSelect
